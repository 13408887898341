import { createTheme } from "@mui/material/styles";
// export type PaletteColorOptions = SimplePaletteColorOptions | ColorPartial;
const theme = createTheme({
  typography: {
      fontFamily: [
        'AnekLatin-Regular',
      ].join(','),
    },
});

export default theme;