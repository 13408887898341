import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import('../pages/Home/Home'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Request = lazy(()=> import('../pages/PartnershipRequest/Request'));
const Swagger = lazy(()=>import('../pages/Swagger/Swagger'));
const ApiDocumentation = lazy(()=>import('../pages/ApiDocumentation/ApiDocumentation'));
const IntegrationFlow = lazy(()=> import('../pages/IntegrationFlow/IntegrationFlow'))
const RequestCreated = lazy(()=> import('../pages/PartnershipRequest/RequestCreated'))
const ForgotPassword = lazy(()=> import('../pages/ForgotPassword/ForgotPassword'))
const IndividualTicketDetails = lazy(()=> import('../pages/Dashboard/IndividualTicketDetails'))
const Index = lazy(()=> import('../pages/Dashboard/Index'))
const FrequentlyAQs = lazy(()=> import('../pages/FrequentlyAskedQuestions/FrequentlyAQs'))
const IntegrationRequestForm = lazy(()=> import('../pages/Dashboard/IntegrationRequestForm'))
const PendingApproval = lazy(()=> import('../pages/Dashboard/PendingApproval'))
const NotFound = lazy(()=> import('../components/NotFound/NotFound'))
const ResetPassword = lazy(()=> import('../pages/SetPassword/ResetPassword'))
const OfferIntegration = lazy(()=> import('../pages/OfferIntegration/OfferIntegration'))
const ContactUsPage = lazy(()=> import('../pages/ContactUs/ContactUsPage'))
const Login = lazy(()=> import('../pages/Login/Login'))
const RequestSubmit = lazy(()=> import('../pages/RequestSubmission/RequestSubmit'))
const Analytics = lazy(()=> import('../pages/Analytics/Analytics'))

const MainRoutes = () => {
  return (
    <Suspense fallback={<div/>}>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/partnership-request" element={<Request />}></Route>
        <Route path="/request-created" element={<RequestCreated />}></Route>
        <Route path="/offer-integration" element={<OfferIntegration />}></Route>
        <Route path="/contact-us" element={<ContactUsPage />}></Route>
        <Route path="/form-submitted" element={<RequestSubmit />}></Route>
        {/* <Route path="/set-password" element={<SetPassword />}></Route> */}
        <Route path="/set-password" element={<ResetPassword />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Index />} />
          <Route path=":swagger" element={<Swagger />} />
          <Route path="/dashboard/analytics" element={<Analytics />} />
          <Route path=":api-documentation" element={<ApiDocumentation />} />
          <Route path=":request-created" element={<RequestCreated />}></Route>
          <Route path=":flow-diagram" element={<IntegrationFlow />} />
          <Route path=":partnership-request" element={<Request />} />
          <Route path=":Frequently-asked" element={<FrequentlyAQs />} />
          <Route
            path=":individual-ticket-detail/:id"
            element={<IndividualTicketDetails />}
          />
          <Route
            path=":partner-integration"
            element={<IntegrationRequestForm />}
          />
          <Route path=":pending-approval" element={<PendingApproval />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>

  );
};

export default MainRoutes;
